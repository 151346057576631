import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { VueCookieNext } from 'vue-cookie-next'
import i18n from '@/i18n'
import jQueryui from 'jquery-ui'
import '@/assets/commonCss.scss'
import '~/assets/fonts/open-sans/style.css';
import Antd from 'ant-design-vue';

 $.extend(jQueryui);

const app = createApp( App )
  .use( router )
  .use( store )
  .use( i18n )
  .use( VueCookieNext )
  .use(Antd)
router.isReady( ).then( ( ) => { app.mount( '#app' ) } )

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
  $t: typeof i18n;
  }
}

export default app;
